<template>
    <div id="app">
        <v-date-picker
            is-inline
            is-expanded
            :tint-color="theme"
            :show-day-popover="false"
            :min-date="new Date()"
            v-model="selectedDate"
        />
        <button
            class="select"
            :disabled="!selectedDate"
            :style="{ background: theme }"
            @click="select"
        >
            {{ selectDate }}
        </button>
    </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5'
import LocalizedStrings from 'localized-strings'

const params = new URLSearchParams(window.location.search)
const locale = params.has('lang')
    ? params.get('lang')
    : new Intl.DateTimeFormat().resolvedOptions().locale

let strings = new LocalizedStrings(
    {
        en: {
            selectDate: 'Select date'
        },
        fr: {
            selectDate: 'Sélectionner'
        },
        de: {
            selectDate: 'Auswählen'
        }
    },
    {
        customLanguageInterface: () => {
            return locale
        }
    }
)

export default {
    data () {
        return {
            theme: '#333333',
            momentsSDK: null,
            selectedDate: null,
            selectDate: strings.selectDate,
            locale
        }
    },
    methods: {
        select () {
            const selectedDate = this.selectedDate.toLocaleDateString(this.locale)
            const formattedDate = selectedDate.replace(/\./g, '/')
            console.log(formattedDate)

            if (!this.selectedDate || !this.momentsSDK) {
                return
            }

            this.momentsSDK.sendMessage({ text: formattedDate })
            this.momentsSDK.close()
        }
    },
    async created () {
        createMomentsSDK({
            title: 'Date picker',
            icon: `${window.location.origin}${window.location.pathname}/favicon.png`
        }).then(momentsSDK => {
            this.momentsSDK = momentsSDK
        })
    }
}
</script>

<style lang="sass">
    *
        margin: 0
        padding: 0
        box-sizing: border-box
        outline: none

    html, body, #app
        height: 100%
        width: 100%

    #app
        padding: 10px

    button.select
        width: 100%
        margin-top: 15px
        padding: 10px
        color: #ffffff
        border: none
        border-radius: 4px
        font-size: 16px
        -webkit-backface-visibility: hidden
        transition: all .3s
        cursor: pointer
        &:hover
            opacity: 0.8
        &:active
            opacity: 0.5
        &:disabled
            opacity: 0.2
</style>
