import Vue from 'vue'
import App from './App.vue'

import { setupCalendar, DatePicker } from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
const params = new URLSearchParams(window.location.search)

setupCalendar({
    locale: params.get('lang')
})

Vue.component('v-date-picker', DatePicker)

Vue.config.productionTip = false

new Vue({
    render: h => h(App)
}).$mount('#app')
